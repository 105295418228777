import {
  createStore
} from 'vuex'

export default createStore({
  state: {
    index: 0
  },
  getters: {},
  mutations: {
    setindex(state, index) {
      state.index = index
    },
  },
  actions: {},
  modules: {}
})