import {
  createRouter,
  createWebHistory
} from 'vue-router'

const routes = [{
    path: '/',
    name: 'MyHome',
    component: () => import('../views/home/MyHome.vue')
  },
  {
    path: '/Introduction',
    component: () => import('../views/Introduction/Introduction.vue')
  },
  {
    path: '/AboutUs',
    component: () => import('../views/AboutUs/AboutUs.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router